import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import Hero from 'components/hero';
import Cta from 'components/cta';
import Features from 'components/features';
import FeaturesSingle from 'components/features-list/featuresSingle';
import Benefits from 'components/benefits';
import Platforms from 'components/platforms';

const Services = ({ data }) => (
  <Layout>
    <Head pageTitle={data.servicesJson.hero.title} />
    <Hero hero={data.servicesJson.hero} />
    <Platforms items={data.servicesJson.platforms} />

    <Cta cta={data.servicesJson.cta} />
    <FeaturesSingle feature={data.servicesJson.feature} isCard={true} />
    <Benefits benefit={data.servicesJson.benefit} columns={3} />
    <FeaturesSingle feature={data.servicesJson.process} />
    <Features feature={data.servicesJson.project} />

    {/* <Cta cta={data.servicesJson.callout} theme="light" /> */}
    <Cta cta={data.servicesJson.cta2} />
  </Layout>
);

Services.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Services;

export const query = graphql`
  query ServicesQuery {
    servicesJson {
      hero {
        title
        content {
          childMarkdownRemark {
            html
          }
        }
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      platforms {
        name
        image {
          childImageSharp {
            fluid(maxHeight: 144, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      feature {
        title
        content
        features {
          position
          headline
          text
          image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 546) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      benefit {
        title
        benefits {
          icon
          headline
          text
        }
      }
      process {
        title
        content
        features {
          position
          icon
          headline
          text
          image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 546) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      project {
        title
        features {
          icon
          headline
          content
          action
          isRedirect
        }
      }
      cta {
        title
        content
        action
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 546) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        action
      }
      cta2 {
        title
        content
        action
      }
    }
  }
`;
